var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.value, width: "600" },
      on: { "click:outside": _vm.cancel }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Copy Promotion")]),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Promotion Name",
                                  "hide-details": "auto",
                                  rules: [
                                    function(v) {
                                      return !!v || "Promotion Name is required"
                                    },
                                    _vm.validateText
                                  ],
                                  outlined: "",
                                  dense: ""
                                },
                                model: {
                                  value: _vm.promoClone.promo_name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.promoClone, "promo_name", $$v)
                                  },
                                  expression: "promoClone.promo_name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Promotion Number",
                                  "hide-details": "auto",
                                  rules: [_vm.promoNumberValidation],
                                  outlined: "",
                                  dense: ""
                                },
                                model: {
                                  value: _vm.promoClone.promo_number,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.promoClone,
                                      "promo_number",
                                      $$v
                                    )
                                  },
                                  expression: "promoClone.promo_number"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-select", {
                                attrs: {
                                  label: "Promotion Category",
                                  "menu-props": { offsetY: true },
                                  items: _vm.validCloneTo,
                                  rules: [
                                    function(v) {
                                      return (
                                        !!v || "Promotion Category is required"
                                      )
                                    }
                                  ],
                                  "item-text": "name",
                                  "item-color": "action",
                                  "hide-details": "auto",
                                  "return-object": "",
                                  outlined: "",
                                  dense: ""
                                },
                                on: { change: _vm.validateDates },
                                model: {
                                  value: _vm.promoClone.promo_category,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.promoClone,
                                      "promo_category",
                                      $$v
                                    )
                                  },
                                  expression: "promoClone.promo_category"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "px-2 py-0" },
                            [
                              _c("PromoDates", {
                                ref: "promoDates",
                                attrs: {
                                  promo: _vm.promoClone,
                                  "hide-details": "auto",
                                  readOnlyPromo: false
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.isScanToComplex
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Buy X",
                                      type: "number",
                                      min: "1",
                                      step: "1",
                                      "hide-details": "auto",
                                      rules: _vm.rules,
                                      outlined: "",
                                      dense: ""
                                    },
                                    model: {
                                      value: _vm.promoClone.buy_x,
                                      callback: function($$v) {
                                        _vm.$set(_vm.promoClone, "buy_x", $$v)
                                      },
                                      expression: "promoClone.buy_x"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Save $Y",
                                      type: "number",
                                      min: "0.01",
                                      step: ".01",
                                      prefix: "$",
                                      "hide-details": "auto",
                                      rules: _vm.rules.concat([
                                        _vm.isValidPrice
                                      ]),
                                      outlined: "",
                                      dense: ""
                                    },
                                    model: {
                                      value: _vm.promoClone.save_y,
                                      callback: function($$v) {
                                        _vm.$set(_vm.promoClone, "save_y", $$v)
                                      },
                                      expression: "promoClone.save_y"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-row",
                        [
                          _vm.promoClone.promo_category
                            ? _c(
                                "v-col",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      label: "Promotion Type",
                                      "item-value": "id",
                                      "item-text": "name",
                                      "item-color": "action",
                                      items: _vm.validCloneBillingModes,
                                      "background-color": "#fff",
                                      rules: [
                                        function(v) {
                                          return (
                                            !!v || "Promotion Type is required"
                                          )
                                        }
                                      ],
                                      dense: "",
                                      "hide-details": "auto",
                                      "return-object": "",
                                      "validate-on-blur": "",
                                      "menu-props": { offsetY: true },
                                      outlined: ""
                                    },
                                    model: {
                                      value: _vm.promoClone.billing_mode,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.promoClone,
                                          "billing_mode",
                                          $$v
                                        )
                                      },
                                      expression: "promoClone.billing_mode"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.showAllowanceFields
                            ? _vm._l(_vm.currentAllowanceFields, function(
                                field,
                                i
                              ) {
                                return _c(
                                  "v-col",
                                  { key: i },
                                  [
                                    _c("v-text-field", {
                                      staticStyle: { "max-width": "254px" },
                                      attrs: {
                                        label: field.name,
                                        type: "number",
                                        min: "0.00",
                                        step: ".01",
                                        prefix: "$",
                                        "hide-details": "auto",
                                        rules: [_vm.isValidAllowancePrice],
                                        error: _vm.showErrorState(
                                          field,
                                          _vm.promoClone
                                        ),
                                        outlined: "",
                                        dense: ""
                                      },
                                      model: {
                                        value: _vm.promoClone["" + field.value],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.promoClone,
                                            "" + field.value,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "promoClone[`${field.value}`]"
                                      }
                                    })
                                  ],
                                  1
                                )
                              })
                            : _vm._e()
                        ],
                        2
                      ),
                      !_vm.promo.promotion_price
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    staticStyle: { "max-width": "254px" },
                                    attrs: {
                                      label: "Promotion Price",
                                      type: "number",
                                      min: "0.00",
                                      step: ".01",
                                      prefix: "$",
                                      "hide-details": "auto",
                                      rules: [_vm.isValidRequiredPrice],
                                      outlined: "",
                                      dense: ""
                                    },
                                    model: {
                                      value: _vm.promoClone.promotion_price,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.promoClone,
                                          "promotion_price",
                                          $$v
                                        )
                                      },
                                      expression: "promoClone.promotion_price"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider", { staticClass: "mt-8" }),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "grey darken-2" },
                  on: { click: _vm.cancel }
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: { loading: _vm.loading, color: "green darken-2" },
                  on: { click: _vm.clonePromo }
                },
                [_vm._v(" Create ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }